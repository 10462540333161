@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #27272a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f3f46;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #52525b;
}

@layer components {
  .stat-tr {
    @apply border border-slate-500 font-semibold p-2 text-gray-300 text-left;
  }
  .stat-td {
    @apply border border-slate-700 p-2 text-gray-400 ;
  }
}
